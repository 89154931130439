import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Home } from '../src/pages_endereco/index';
import { Qrcode } from '../src/pages_qr-code/index';
import { Conferencia } from '../src/pages_conferencia/index';
import { Divergencia } from '../src/pages_divergencia/index';
import { Exportacao } from '../src//pages_qr-code/exportacao';
import HomeLoja from '../src/pages_loja/index';
import { Faltantes } from './pages_qr-code/faltantes';
import { Login2 } from './pages_loja/index2';
import { Periodo } from './pages_qr-code/index3';
import { EnderecosVazio } from './pages_endereco_vazio/index';
import { Pesquisa } from './pages_qr-code/index4';

function App() {
  return (
    <div>
      <Router>
        <Switch>
        <Route path="/faltantes/:id/:periodo" component={Faltantes} />
          <Route path="/home/:id/:periodo" component={Home} />
          <Route path="/qrcode" component={Qrcode} />
          <Route path="/conferencia/:id/:periodo" component={Conferencia} />
          <Route path="/divergencia/:id/:periodo" component={Divergencia} />
          <Route path="/exportacao/:id/:periodo" component={Exportacao} />
          <Route exact path="/" component={HomeLoja} />         
          <Route exact path="/login2" component={Login2} />
          <Route exact path="/periodo/:id/:periodo" component={Periodo} />
          <Route exact path="/enderecosvazios/:id/:periodo" component={EnderecosVazio} />
          <Route exact path="/pesquisa/:id/:periodo" component={Pesquisa} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

