import React, { useState, useEffect } from 'react';
import { Table, ButtonPrimary, Input } from './styles';
import { Link, useHistory, useParams } from 'react-router-dom';
import './style.css';
import axios from 'axios';

export const Pesquisa = () => {
    const [selectedProductId, setSelectedProductId] = useState('');
    const [products, setProducts] = useState({});
    const [selectedProduct, setSelectedProduct] = useState(null);
    const { id, periodo } = useParams();

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get('https://fernandotelini.com.br/api_cherie/componentes_qr_code/pesquisa.php');
            setProducts(response.data.records || {});
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const fetchProductDetails = async (productId) => {
        try {
            const response = await axios.get('https://fernandotelini.com.br/api_cherie/componentes_qr_code/pesquisa.php', {
                params: {
                    id: productId
                }
            });
            const selectedProductData = response.data.records[productId];
            setSelectedProduct(selectedProductData || null);
        } catch (error) {
            console.error('Error fetching product details:', error);
        }
    };
    const handleProductChange = (productId) => {
        setSelectedProductId(productId);
        fetchProductDetails(productId);
    };

    return (
        <div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <select
                    className="select_product"
                    value={selectedProductId}
                    onChange={(e) => handleProductChange(e.target.value)}
                >
                    <option value="">Selecione um produto</option>
                    {Object.keys(products).map((productId, index) => (
                        <option key={index} value={productId}>{products[productId].nome}</option>
                    ))}
                </select>
                <ButtonPrimary className='btn_pesquisa' >Pesquisar</ButtonPrimary>
            </div>

            <Table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>SKU</th>
                        <th>EAN</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedProduct && (
                        <tr>
                            <td>{selectedProduct.nome}</td>
                            <td>{selectedProduct.sku}</td>
                            <td>{selectedProduct.ean}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Link to={`/exportacao/${id}/${periodo}`}>
                <ButtonPrimary className="btn_excel">Voltar</ButtonPrimary>
            </Link>
        </div>
    );
};

export default Pesquisa;
