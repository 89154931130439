import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ButtonPrimary, Container, Table } from './styles';
import './style2.css';

export const Faltantes = () => {
  const [dados, setDados] = useState([]);
  const { id, periodo } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://fernandotelini.com.br/api_cherie/componentes_qr_code/comparacao.php?id_loja=${id}&id_periodo=${periodo}`);
        setDados(response.data.enderecos);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
  }, [id, periodo]);

  const dividirEmColunas = (dados, numeroDeColunas) => {
    const linhas = Math.ceil(dados.length / numeroDeColunas);
    const resultado = [];

    for (let i = 0; i < linhas; i++) {
      const linha = [];

      for (let j = 0; j < numeroDeColunas; j++) {
        const index = i * numeroDeColunas + j;
        if (index < dados.length) {
          linha.push(dados[index]);
        }
      }

      resultado.push(linha);
    }

    return resultado;
  };

  const dadosEmColunas = dividirEmColunas(dados, 10);

  const getColorForPrefix = (prefix) => {
    switch (prefix) {
      case 'E':
        return 'red';
      case 'L':
        return 'blue';
      case 'X':
        return 'gray';
      default:
        return 'black'; 
    }
  };

  return (
    <Container>
      <ButtonPrimary id='btn_prin'>
        <Link to={`/exportacao/${id}/${periodo}`} className='btn_voltar'> Voltar</Link>
      </ButtonPrimary>
      <Table>
        <tbody>
          {dadosEmColunas.map((linha, rowIndex) => (
            <tr key={rowIndex}>
              {linha.map((endereco) => (
                <td className='td_fal' key={endereco.endereco_id} style={{ color: getColorForPrefix(endereco.endereco_nome.charAt(0)) }}>{endereco.endereco_nome}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Faltantes;
