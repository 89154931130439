import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './style.css'
import { ButtonSuccess } from '../pages_endereco/styles';

export const Login2 = () => {
  const [lojas, setLojas] = useState([]);
  const [selectedLoja, setSelectedLoja] = useState(null);
  const history = useHistory();
  const [senha, setSenha] = useState('');
  const [usuario, setUsuario] = useState('');
  const [erro, setErro] = useState('');
  const [periodo, setPeriodo] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState(null);
  const [periodoFiltrado, setPeriodoFiltrado] = useState([]);

  useEffect(() => {
    fetch('https://fernandotelini.com.br/api_cherie/componentes_loja/index.php')
      .then(response => response.json())
      .then(data => {
        const lojasArray = Object.values(data.records);
        setLojas(lojasArray);
      })
      .catch(error => console.error('Erro ao buscar lojas:', error));
  }, []);

  useEffect(() => {
    fetch('https://fernandotelini.com.br/api_cherie/componentes_periodo/index.php')
      .then(response => response.json())
      .then(data => {
        const periodoArray = Object.values(data);
        setPeriodo(periodoArray);
        setPeriodoFiltrado(periodoArray); 
      })
      .catch(error => console.error('Erro ao buscar Periodo:', error));
  }, []);

  const handleLojaChange = (e) => {
    const idLojaSelecionada = e.target.value;
    setSelectedLoja(lojas.find(loja => loja.id.toString() === idLojaSelecionada));

    const periodosDaLoja = periodo.filter(periodoObj => {
      const periodo = periodoObj[Object.keys(periodoObj)[0]];
      return periodo.id_loja.toString() === idLojaSelecionada;
    });
  
    setPeriodoFiltrado(periodosDaLoja);
  };

  const handleIniciarInventario = () => {
    if (!selectedLoja || !selectedLoja.id) {
      setErro('Selecione uma loja antes de iniciar o inventário.');
      return;
    }
  
    if (!selectedPeriodo || !selectedPeriodo[Object.keys(selectedPeriodo)[0]].id) {
      setErro('Selecione um período antes de iniciar o inventário.');
      return;
    }
  
    if (!usuario || !senha) {
      setErro('Preencha todos os campos antes de iniciar o inventário.');
      return;
    }
    

    const periodoId = selectedPeriodo ? selectedPeriodo[Object.keys(selectedPeriodo)[0]].id : null;

    const data = {
      usuario: usuario,
      senha: senha,
      id_loja: selectedLoja.id,
      periodo: periodoId  
    };

    console.log('Info',data)
    fetch('https://fernandotelini.com.br/api_cherie/componentes_loja/autenticacao.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          history.push(`/exportacao/${selectedLoja.id}/${selectedPeriodo[Object.keys(selectedPeriodo)[0]].id}`);
        } else {
          setErro('Credenciais inválidas ou loja/status inválidos');
        }
      })
      .catch(error => {
        console.error('Erro ao autenticar:', error);
        setErro('Erro ao autenticar. Tente novamente.');
      });
  };

  return (
    <div className='container'>
    <h1>Login de Administração</h1>
      <div className="login-page">
        <div className="form">
          <div>
            <select className="selt_loja" onChange={(e) => handleLojaChange(e)}>
              <option className='opt_loja' value="">Selecione uma loja</option>
              {lojas.map((loja) => (
                <option key={loja.id} value={loja.id}>{loja.nome}</option>
              ))}
            </select>

            <select className="selt_loja" onChange={(e) => setSelectedPeriodo(periodoFiltrado.find(periodo => periodo[Object.keys(periodo)[0]].id.toString() === e.target.value))}>
              <option className='opt_loja' value="">Selecione o periodo</option>
              {periodoFiltrado.map((periodoObj) => {
                const periodo = periodoObj[Object.keys(periodoObj)[0]];
                return (
                  <option key={periodo.id} value={periodo.id}>{periodo.descricao}</option>
                );
              })}
            </select>
            <div>
              <input
                type="text"
                placeholder="Nome de Usuário"
                value={usuario}
                onChange={(e) => setUsuario(e.target.value)}
              />
              <input
                type="password"
                placeholder="Senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />
              <ButtonSuccess className='btn_loja' onClick={handleIniciarInventario}>Iniciar Inventário</ButtonSuccess>

              {erro && (
                <div className={`error-message ${erro ? '' : 'no-error'}`} role="alert">
                  {erro}
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Login2;
