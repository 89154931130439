import React, { useState, useEffect } from 'react';
import { Table, ButtonPrimary } from './styles';
import { Link, useHistory, useParams } from 'react-router-dom';
import './style.css';
import axios from 'axios';

export const Periodo = () => {
    const [lojas, setLojas] = useState([]);
    const [idLoja, setIdLoja] = useState('');
    const [periodo2, setPeriodo2] = useState('');
    const [status, setStatus] = useState('');
    const [periodos, setPeriodos] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id, periodo } = useParams();
    const params = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [lojasResponse, periodosResponse] = await Promise.all([
                    axios.get('https://fernandotelini.com.br/api_cherie/componentes_loja/index.php'),
                    axios.get('https://fernandotelini.com.br/api_cherie/componentes_loja/periodo2.php')
                ]);

                const lojasArray = Object.values(lojasResponse.data.records);
                const periodosArray = Object.values(periodosResponse.data.records);

                setLojas(lojasArray);
                setPeriodos(periodosArray);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar lojas e períodos:', error);
            }
        };

        fetchData();
    }, []);

    const getNomeDaLoja = (idLoja) => {
        if (loading) return 'Carregando...';
        const loja = lojas.find(loja => loja.id === parseInt(idLoja));
        return loja ? loja.nome : '';
    };

    const handleCadastro = async () => {
        try {
            const response = await axios.post('https://fernandotelini.com.br/api_cherie/componentes_loja/cadastrar_periodo.php', {
                id_loja: idLoja,
                descricao: periodo2,
            });
            if (response.data && response.data.erro === false) {
                console.log('Cadastrado com sucesso');
            } else {
                console.error('Erro ao cadastrar:', response.data.mensagem);
            }
        } catch (error) {
            console.error('Erro ao cadastrar:', error);
        }
        window.location.reload('');
    };

    const handleCheckboxChange = async (periodoId, newStatus) => {
        try {
            await axios.post('https://fernandotelini.com.br/api_cherie/componentes_loja/atualizar_status_periodo.php', {
                periodoId,
                newStatus,
            });

            setPeriodos(prevPeriodos =>
                prevPeriodos.map(periodo =>
                    periodo.id == periodoId ? { ...periodo, statusbd: newStatus } : periodo
                )
            );
        } catch (error) {
            console.error('Erro ao atualizar status do período:', error);
        }
    }


    return (
        <div>
            <div className='inp_periodo'>
                <input
                    type="text"
                    placeholder="Período"
                    className='inp_periodo2'
                    value={periodo2}
                    onChange={(e) => setPeriodo2(e.target.value)}
                />
            </div>
            <div className='select_periodo'>
                <select
                    className='select_periodo2'
                    value={idLoja}
                    onChange={(e) => setIdLoja(e.target.value)}
                >
                    <option value="">Selecione uma loja</option>
                    {lojas.map((loja) => (
                        <option key={loja.id} value={loja.id}>{loja.nome}</option>
                    ))}
                </select>
            </div>
            <button className='btn_cad_periodo' onClick={handleCadastro}>Cadastrar</button>
            <Table className='tabela_periodo'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Período</th>
                        <th>Loja</th>
                        <th>Ativo?</th>
                    </tr>
                </thead>
                <tbody>
                    {periodos.map(periodo => (
                        <tr key={periodo.id}>
                            <td>{periodo.id}</td>
                            <td>{periodo.descricao}</td>
                            <td>{getNomeDaLoja(periodo.id_loja)}</td>
                            <td className="center-checkbox">
                                <input
                                    type="checkbox"
                                    className='inp_status'
                                    checked={periodo.statusbd === 1}
                                    onChange={(e) => handleCheckboxChange(periodo.id, e.target.checked ? 1 : 2)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Link to={`/exportacao/${id}/${periodo}`}>
                <ButtonPrimary className="btn_excel">Voltar</ButtonPrimary>
            </Link>
        </div>
    );
};

export default Periodo;
