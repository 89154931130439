import React, { useState, useEffect } from 'react';
import { ButtonPrimary, Container, Table } from './styles';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import axios from 'axios';
import './style.css'

export const Exportacao = () => {
  const history = useHistory();
  const [exportedData, setExportedData] = useState([]);
  const [userData, setUserData] = useState([]);
  const { id, periodo } = useParams();
  const params = useParams();
  console.log('params4:', params);
  console.log('teste4:', params.periodo);

  useEffect(() => {
    fetch(`https://fernandotelini.com.br/api_cherie/componentes_conferencia/exportacao.php?id_loja=${id}&id_periodo=${periodo}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.records) {
          setExportedData(data.records);
        }
      })
      .catch(error => console.error('Erro ao obter dados de exportação:', error));
  }, [id, periodo]);

  useEffect(() => {
    fetch('https://fernandotelini.com.br/api_cherie/componentes_loja/gerencia_user.php')
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok, status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('User Data:', data);

        if (data && data.records) {
          const userArray = Object.values(data.records);
          setUserData(userArray);
        }
      })
      .catch(error => console.error('Error fetching user data:', error));
  }, []);


  const handleCheckboxChange = async (userId, newStatus) => {
    try {
      await axios.post('https://fernandotelini.com.br/api_cherie/componentes_loja/atualiza_status.php', {
        userId,
        newStatus,
      });

      setUserData(prevData =>
        prevData.map(user =>
          user.id == userId ? { ...user, statusbd: newStatus } : user
        )
      );
    } catch (error) {
      console.error('Erro ao atualizar status do usuário:', error);
    }
  }
  const handleExportClick = () => {
    if (exportedData && exportedData.length > 0) {
      const txtContent = exportedData.map(item => `${item.ean};${item.quantidade}`).join('\n');
  
      const blob = new Blob([txtContent], { type: 'text/plain' });
  
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'inventarioloja.txt';
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.warn('Nenhum dado disponível para exportação.');
    }
  };
  

  //inicio excel
  const exportarParaExcel = async () => {
    try {
      const response = await axios.get(`https://fernandotelini.com.br/api_cherie/componentes_conferencia/exportacao2.php?id_loja=${id}&id_periodo=${periodo}`);
      const dados = response.data;
  
      if (dados && dados.records) {
        const dadosFinais = [];
        Object.keys(dados.records).forEach(endereco => {
          const produtosPorEndereco = {};
          dados.records[endereco].forEach(produto => {
            const { sku, ean } = produto;
            const chave = sku || ean;
            if (!produtosPorEndereco[chave]) {
              produtosPorEndereco[chave] = {
                Endereco: endereco,
                SKU: sku || '',
                Nome: produto.nome || '',
                EAN: ean || '',
                Quantidade: 1
              };
            } else {
              produtosPorEndereco[chave].Quantidade++;
            }
          });
  
          Object.values(produtosPorEndereco).forEach(produto => {
            dadosFinais.push(produto);
          });
        });
  
        const ws = XLSX.utils.json_to_sheet(dadosFinais);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Inventário');
  
        XLSX.writeFile(wb, 'inventario.xlsx');
      } else {
        console.warn('Nenhum dado disponível para exportação.');
      }
    } catch (error) {
      console.error('Erro ao exportar para Excel:', error);
    }
  };
  

  return (
    <div>
      <h1>Administração</h1>
      <ButtonPrimary onClick={handleExportClick}>Exportar para txt</ButtonPrimary>
      <ButtonPrimary className="btn_excel" onClick={exportarParaExcel}>Exportar para xlsx</ButtonPrimary>
      <Link to="/qrcode">
        <ButtonPrimary className="btn_excel">QR-Code</ButtonPrimary>
      </Link>

      <ButtonPrimary className='btn_excel'>
        <Link to={`/faltantes/${id}/${periodo}`} className='btn_excel'>Endereços Faltantes</Link>
      </ButtonPrimary>
      <ButtonPrimary className='btn_excel'>
        <Link to={`/periodo/${id}/${periodo}`} className='btn_excel'>Gerenciar Periodo</Link>
      </ButtonPrimary>
      <br></br> <br></br>
      <ButtonPrimary className='btn_pesquisa1'>
        <Link to={`/pesquisa/${id}/${periodo}`} className='btn_excel'>Consultar Produto</Link>
      </ButtonPrimary>
      <Table>
        <thead>
          <tr>
            <th>Usuário</th>
            <th>Ativo?</th>
          </tr>
        </thead>
        <tbody>
          {userData.map(user => (
            <tr key={user.id}>
              <td>{user.usuario}</td>
              <td className="center-checkbox">
                <input
                  type="checkbox"
                  className='inp_status'
                  checked={user.statusbd === 1}
                  onChange={(e) => handleCheckboxChange(user.id, e.target.checked ? 1 : 2)}
                />
              </td>
            </tr>
          ))}
        </tbody>

      </Table>
      <br></br><br></br>
    </div>

  );
};

export default Exportacao;
