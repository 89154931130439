import styled from 'styled-components';


export const Container = styled.section`
    // max-width: 1800px ;
    width: 97% !important;
    margin: 20px auto;
    margin-top:3px;
    margin-left: -15px;
    box-shadow: 0 0 1em #6c757d;
    padding: 0px 20px 20px;
`;


export const ConteudoTitulo = styled.section`
    display: flex;
    justify-content: space-between;
`;

export const BotaoAcao = styled.section`
    margin: 25px 0px;
`;


export const ButtonSuccess = styled.button`
    background-color: #fff;
    color: #198754;
    border: 1px solid #198754;
    padding: 5px 8px;
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 16px;
    :hover{
        background-color: #157347;
        color: #fff;
    }
`;

export const ButtonPrimary = styled.button`
    background-color: #fff;
    color: #0d6efd;
    padding: 5px 8px;
    border: 1px solid #0d6efd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    :hover{
        background-color: #0d6efd;
        color: #fff;
    }
`;

export const ButtonDanger = styled.button`
  background-color: #ff6961;
  color: white;
  padding: 5px 8px;
  border: none; /* Removendo a borda */
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 30px;

  &:hover {
    background-color: darkred;
  }
`;

export const ButtonWarning = styled.button`
    background-color: #fff;
    color: #ffc107;
    padding: 5px 8px;
    border: 1px solid #ffc107;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    :hover{
        background-color: #ffc107;
        color: #fff;
    }
`;

export const Titulo = styled.h1`
    color: #3e3e3e;
    font-size: 23px;
`;

export const Table = styled.table`
    width: 100%;
    margin-bottom:10px;
    th{
        text-align: center;
        background-color: #a9a9a9;
        color: #3e3e3e;
        padding: 10px;
    }
    td{
        background-color: #f6f6f6;
        color: #3e3e3e;
        padding: 8px;
        text-align: center;
    }
`;

