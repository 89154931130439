import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ButtonDanger, ButtonPrimary, Container, Table } from './styles';
import styled from 'styled-components';
import './style.css'
const NavBar = styled.nav`
  background-color: #FFFFFF;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Divergencia = () => {
  const [divergencias, setDivergencias] = useState([]);
  const { id, periodo } = useParams();

  useEffect(() => {
    fetch(`https://fernandotelini.com.br/api_cherie/componentes_divergencia/index.php?id_loja=${id}&id_periodo=${periodo}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.records) {
          const records = Object.values(data.records);
          setDivergencias(records);
        }
      })
      .catch(error => console.error('Erro ao obter divergências:', error));
  }, [id, periodo]);

  const handleApagarClick = async (enderecoId) => {
    try {
      const response = await fetch('https://fernandotelini.com.br/api_cherie/componentes_divergencia/excluir_endereco.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          enderecoId: enderecoId,
        }),
      });

      const result = await response.json();

      if (result.success) {
        console.log(`Endereço com ID ${enderecoId} excluído com sucesso`);
        setDivergencias(prevDivergencias => prevDivergencias.filter(divergencia => divergencia.id !== enderecoId));
      } else {
        console.error('Erro ao excluir o endereço:', result.message);
      }
      window.location.reload();
    } catch (error) {
      console.error('Erro na requisição para excluir o endereço:', error);
    }
  };

  return (
    <Container>
      <NavBar>
        <ButtonPrimary>
          <Link to={`/conferencia/${id}/${periodo}`} className='link_inventario'>
            Conferencia
          </Link>
        </ButtonPrimary>
        <ButtonPrimary>
          <Link to={`/home/${id}/${periodo}`} className='link_qr-code'>
            Conferencia
          </Link>
        </ButtonPrimary>
      </NavBar>
      <div>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Endereco</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {divergencias.map(divergencia => (
              <tr key={divergencia.id}>
                <td>{divergencia.id}</td>
                <td>{divergencia.endereco}</td>
                <td>
                  <ButtonDanger className='btn_divergencia' onClick={() => handleApagarClick(divergencia.endereco)}>
                    Apagar
                  </ButtonDanger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* <ButtonPrimary className='link_vazios'>
          <Link to={`/enderecosvazios/${id}/${periodo}`}className='link_vazio'>
            Endereços Vazios
          </Link>
        </ButtonPrimary> */}
      </div>
    </Container>
  );
};

export default Divergencia;
