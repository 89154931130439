import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ButtonDanger, ButtonPrimary, ButtonSuccess, Container, Table } from './styles';
import styled from 'styled-components';
import './style.css';

const NavBar = styled.nav`
  background-color: #FFFFFF;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Conferencia = () => {
  const addressInputRef = useRef(null);
  const [address, setAddress] = useState('');
  const [qrCodeRead, setQrCodeRead] = useState(false);
  const [eanList, setEanList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { id, periodo } = useParams();
  const params = useParams();
  console.log('params2:', params);
  console.log('teste2:', params.periodo);
  useEffect(() => {
    if (qrCodeRead && addressInputRef.current) {
      addressInputRef.current.focus();
      setQrCodeRead(false);
  
      if (address.trim() !== '' && id && periodo) {
        fetchEANsFromAPI(address, id, periodo);
      }
    }
  }, [qrCodeRead, address, id, periodo]);

  const handleAddressInputChange = (event) => {
    setAddress(event.target.value);
    setErrorMessage(''); 
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (address.trim() !== '13' && id && periodo) {
        fetchEANsFromAPI(address, id, periodo);
      }
    }
  };

  const fetchEANsFromAPI = async (endereco, id, periodo) => {
    try {
      const response = await fetch(`https://fernandotelini.com.br/api_cherie/componentes_endereco/confere.php?endereco=${endereco}&id_loja=${id}&id_periodo=${periodo}`);
      const data = await response.json();
      if (data && data.records) {
        const records = data.records;
        if (records.length > 0) {
          const eans = records.flatMap(record => record.eans || []);
          setEanList(eans);
        } else {
          console.warn('Endereço encontrado, mas sem EAN:', endereco);
          setEanList([]);
          setErrorMessage('Este endereço pode já ter sido conferido ou ainda não foi bipado.');
        }
      } else {
        console.warn('Endereço não encontrado ou resposta vazia:', endereco);
        setEanList([]);
        setErrorMessage('Este endereço pode já ter sido conferido ou ainda não foi bipado.');
      }
    } catch (error) {
      console.error('Erro ao obter os EANs da API:', error);
      setEanList([]);
      setErrorMessage('Erro ao obter os EANs da API.');
    }
  };

  const handleButtonClick = async (status) => {
    try {
      const response = await fetch('https://fernandotelini.com.br/api_cherie/componentes_conferencia/atualizar_status.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          endereco: address,
          status: status,
        }),
      });
      const result = await response.json();

      if (result.success) {
        console.log(`Status atualizado para ${status}`);
        window.location.reload();
      } else {
        console.error('Erro ao atualizar o status:', result.message);
      }
    } catch (error) {
      console.error('Erro na requisição para atualizar o status:', error);
    }
  };

  return (
    <Container>
      <NavBar>
        <ButtonPrimary>
          <Link to={`/home/${id}/${periodo}`} className='link_inventario'>Inventariar</Link>
        </ButtonPrimary>
        <ButtonPrimary>
          <Link to={`/divergencia/${id}/${periodo}`} className='link_qr-code'>Divergencia</Link>
        </ButtonPrimary>
      </NavBar>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <input
        type="text"
        className='inp_endereco_conf'
        placeholder="Mire o QR Code aqui"
        ref={addressInputRef}
        value={address}
        onChange={handleAddressInputChange}
        onKeyPress={handleKeyPress}
      />
      <h1>Endereço: {address}</h1>

      {eanList.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>N°</th>
              <th>EAN</th>
            </tr>
          </thead>
          <tbody>
            {eanList.map((ean, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{ean}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <ButtonDanger className='btn_divergencia' onClick={() => handleButtonClick(3)} type="submit">Divergencia</ButtonDanger>
      <ButtonSuccess className='btn_conferido' onClick={() => handleButtonClick(2)} type="submit" >Conferido</ButtonSuccess>
    </Container>
  );
}

export default Conferencia;