import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ButtonPrimary, ButtonSuccess, Container, Table } from './styles';
import styled from 'styled-components';
import './style.css';

const NavBar = styled.nav`
  background-color: #FFFFFF;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EnderecosVazio = () => {
  const addressInputRef = useRef(null);
  const [address, setAddress] = useState('');
  const [qrCodeRead, setQrCodeRead] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { id, periodo } = useParams();
  const params = useParams();
  console.log('params2:', params);
  console.log('teste2:', params.periodo);
  useEffect(() => {
    if (qrCodeRead && addressInputRef.current) {
      addressInputRef.current.focus();
      setQrCodeRead(false);
    }
  }, [qrCodeRead]);

  const handleAddressInputChange = (event) => {
    setAddress(event.target.value);
    setErrorMessage(''); 
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      if (address.trim() !== '' && id && periodo) {
        const confirmed = window.confirm('Tem certeza que o endereço está vazio?');
        if (confirmed) {
          await handleButtonClick(2);
        }
      }
    }
  };

  const handleButtonClick = async (status) => {
    try {
      const response = await fetch(`https://fernandotelini.com.br/api_cherie/componentes_endereco/confere.php?endereco=${address}&id_loja=${id}&id_periodo=${periodo}`);
      const data = await response.json();
      if (data && data.records && data.records.length > 0) {
        console.log('Endereço já foi lido anteriormente. Não é possível atualizar o status.');
        setErrorMessage('Este endereço já foi lido anteriormente. Não é possível atualizar o status.');
      } else {
        console.log('Endereço ainda não foi lido anteriormente. Atualizando o status...');
        const updateResponse = await fetch('https://fernandotelini.com.br/api_cherie/componentes_conferencia/atualizar_status.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            endereco: address,
            status: status,
          }),
        });
        const result = await updateResponse.json();
  
        if (result.success) {
          console.log(`Status atualizado para ${status}`);
          window.location.reload();
        } else {
          console.error('Erro ao atualizar o status:', result.message);
        }
      }
    } catch (error) {
      console.error('Erro na requisição para verificar o endereço:', error);
      setErrorMessage('Erro ao verificar o endereço na API.');
    }
  };
  
  const fetchEANsFromAPI = async (endereco, id, periodo) => {
    try {
      const response = await fetch(`https://fernandotelini.com.br/api_cherie/componentes_endereco/confere.php?endereco=${endereco}&id_loja=${id}&id_periodo=${periodo}`);
      const data = await response.json();
      if (data && data.records && data.records.length > 0) {
        console.log('Endereço já foi lido anteriormente.');
        setErrorMessage('Este endereço já foi lido anteriormente.');
      } else {
        console.log('Endereço ainda não foi lido anteriormente.');
        setErrorMessage('Este endereço ainda não foi lido anteriormente.');
      }
    } catch (error) {
      console.error('Erro ao verificar o endereço na API:', error);
      setErrorMessage('Erro ao verificar o endereço na API.');
    }
  };
  

  return (
    <Container>
      <NavBar>
        <ButtonPrimary>
          <Link to={`/home/${id}/${periodo}`} className='link_inventario'>Inventariar</Link>
        </ButtonPrimary>
        <ButtonPrimary>
          <Link to={`/divergencia/${id}/${periodo}`} className='link_qr-code'>Divergencia</Link>
        </ButtonPrimary>
      </NavBar>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <input
        type="text"
        className='inp_endereco_conf'
        placeholder="Mire o QR Code aqui"
        ref={addressInputRef}
        value={address}
        onChange={handleAddressInputChange}
        onKeyPress={handleKeyPress}
      />
      <h1>Endereço: {address}</h1>

      <ButtonSuccess className='btn_conferido_vazio' onClick={() => handleButtonClick(2)} type="submit" >Conferido</ButtonSuccess>
    </Container>
  );
}

export default EnderecosVazio;
