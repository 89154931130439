import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Container, ConteudoTitulo, BotaoAcao, ButtonSuccess, Table, Titulo, ButtonPrimary, ButtonDanger } from './styles';

import styled from 'styled-components';

const NavBar = styled.nav`
  background-color: #FFFFFF;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Home = () => {
  const history = useHistory();
  const [address, setAddress] = useState('');
  const [ean, setEan] = useState('');
  const [eanList, setEanList] = useState([]);
  const [qrCodeRead, setQrCodeRead] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAddressInventoried, setIsAddressInventoried] = useState(false);
  const { id, periodo } = useParams();
  const addressInputRef = useRef(null);
  const eanInputRef = useRef(null);

  useEffect(() => {
    if (qrCodeRead && addressInputRef.current) {
      addressInputRef.current.focus();
      setQrCodeRead(false);
    }
  }, [qrCodeRead, addressInputRef.current]);

  const handleAddressInputChange = (event) => {
    setAddress(event.target.value);
  };

  const handleEanInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d*$/.test(inputValue)) {
      setEan(inputValue);
      if (inputValue.length !== 13 && inputValue.length > 0) {
        setErrorMessage('O EAN deve ter exatamente 13 dígitos.');
      } else {
        setErrorMessage('');
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (address && !ean && !errorMessage && !isAddressInventoried) {
        eanInputRef.current.focus();
      } else if (ean && ean.length === 13) {
        setEanList((prevList) => [...prevList, ean]);
        setEan('');
        setErrorMessage('');
      } else {
        setErrorMessage('O EAN deve ter exatamente 13 dígitos.');
      }
    }
  };

  const handleScan = (data) => {
    if (ean || errorMessage || isAddressInventoried) {
      return;
    }

    if (data) {
      setAddress(data);
      setQrCodeRead(true);
      setIsAddressInventoried(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
    setIsAddressInventoried(true);
  };

  const enviarDadosParaPHP = () => {
    if (!address || !eanList.length || !id) {
      console.error('Dados inválidos para envio!');
      return;
    }

    const dadosParaEnviar = {
      endereco: address,
      eanList: eanList,
      id_loja: id,
      id_periodo: periodo
    };

    fetch('https://fernandotelini.com.br/api_cherie/componentes_endereco/cadastrar.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dadosParaEnviar),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Resposta do PHP:', data);
        setAddress('');
        setEan('');
        setEanList([]);
      })
      .catch((error) => {
        console.error('Erro na requisição:', error);
      });
  };

  useEffect(() => {
    if (address && id && periodo) {
      const url = `https://fernandotelini.com.br/api_cherie/componentes_endereco/endereco.php?id_loja=${id}&id_periodo=${periodo}`;

      fetch(url)
        .then((response) => {
          if (!response.ok) {
            console.error('Erro na requisição:', response.statusText);
            return null;
          }
          return response.json();
        })
        .then((data) => {
          if (data === null) {
            return;
          }

          const records = data;
          if (records && Object.keys(records).length > 0) {
            const enderecoRegistrado = Object.keys(records).find(endereco => endereco === address);
            if (enderecoRegistrado) {
              setErrorMessage(`Endereço ${enderecoRegistrado} já foi inventariado!`);
              setIsAddressInventoried(true);
            } else {
              setErrorMessage('');
              setIsAddressInventoried(false);
            }
          } else {
            setErrorMessage('');
            setIsAddressInventoried(false);
          }
        })
        .catch((error) => {
          console.error('Erro na requisição:', error);
          setErrorMessage('Erro ao verificar o endereço');
        });
    }
  }, [address, id, periodo]);

  return (
    <Container>
      <div>
        <NavBar>
          <ButtonPrimary>
            <Link to={`/conferencia/${id}/${periodo}`} className='link_inventario'>Conferencia</Link>
          </ButtonPrimary>
          <ButtonPrimary>
            <Link to={`/divergencia/${id}/${periodo}`} className='link_qr-code'>Divergencia</Link>
          </ButtonPrimary>
        </NavBar>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <input
          type="text"
          className='inp_endereco'
          placeholder="Mire o QR Code aqui"
          ref={addressInputRef}
          value={address}
          onChange={handleAddressInputChange}
          onKeyPress={handleKeyPress}
        />
        <h1>Endereço: {address}</h1>
        <input
          type="text"
          className='inp_ean'
          placeholder="Leia o código EAN"
          ref={eanInputRef}
          value={ean}
          onChange={handleEanInputChange}
          onKeyPress={handleKeyPress}
          disabled={isAddressInventoried}
        />

        <div>
          <Table>
            <thead >
              <tr>
                <th>N°</th>
                <th>EAN</th>
              </tr>
            </thead>
            <tbody>
              {eanList.map((ean, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{ean}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <ButtonSuccess className='btn_cadastrar' onClick={enviarDadosParaPHP} type="submit">Cadastrar</ButtonSuccess>
        </div>
      </div>
    </Container>
  );
};

export default Home;
