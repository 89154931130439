import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode-generator';
import { Table } from './styles';
import './style.css'
export const Qrcode = () => {
  const [qrCodeData, setQrCodeData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://fernandotelini.com.br/api_cherie/componentes_qr_code/index.php');
        const data = await response.json();
        const qrCodeData = Object.values(data.records).map(record => ({
          nome: record.nome,
          url: generateQRCodeURL(record.nome),
        }));

        setQrCodeData(qrCodeData);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
      }
    };

    fetchData();
  }, []);

  const generateQRCodeURL = (nome) => {
    const tipoQR = QRCode(0, 'L');
    tipoQR.addData(nome);
    tipoQR.make();
    return tipoQR.createDataURL(4);
  };

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const chunkedQrCodeData = chunkArray(qrCodeData, 3);

  return (
    <Table>
      <tbody>
        {chunkedQrCodeData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((item, columnIndex) => (
              <React.Fragment key={columnIndex}>
                <td>
                  <img className='img_tab' src={item.url} alt={`QR Code ${rowIndex * 3 + columnIndex + 1}`} />
                  <td className='txt_tab'>{item.nome}</td>
                </td>
              </React.Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default Qrcode;
